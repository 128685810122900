<template>
    <div class="home">
        <div class="homeComAbout">
<!--            <h1 class="homeTitle center">-->
<!--                <img style="width: 60px;height: 60px;" src="@/assets/logo5.png" alt="">-->
<!--              贵州浩佑暖通制冷设备有限公司-->
<!--            </h1>-->
            <div class="banner-img">
                <img src="@/assets/img1.jpg" alt="" style="width: 430px;height: 400px;margin-left: 100px;float: left;">
                <div style="float: left;width: 780px;margin-left: 30px;font-size: 16px;">
                    家庭服务现已遍布去全国25个城市，为数百万家庭提供综合的专业家政服务，包括专业保洁、家庭保洁、家庭收纳、家电清洗、家居养护、洗护服务、生活急救箱等七大类近百项服务，涵盖保洁、保养、洗护、维修等全方位的家庭生活服务需求。
                </div>
                <div style="float: left;width: 780px;margin-left: 30px;font-size: 16px;margin-top: 20px;">
                    <div style="margin-top: 15px;position: relative">
                        <img style="width: 80px;height: 80px;" src="@/assets/logo5.png" alt="">
                        <img style="width: 80px;height: 80px;margin-left: 30px;" src="@/assets/QRCode_420.png" alt="">
                        <img style="width: 80px;height: 80px;margin-left: 20px;" src="@/assets/QRCode_421.png" alt="">
                        <div style="position: absolute;left: 106px">Android下载</div>
                        <div style="position: absolute;left: 220px">Ios下载</div>
                    </div>
                    <div style="font-weight: bold;font-size: 20px;">应用介绍：</div>
                    <div>
                        东方到家，提供保洁包年、深度清洁、保姆服务、家庭用品等专业O2O 家政服务，全自营非中介，旗下拥有众多员工+员工制服务人员，是千万家庭用户的共同选择。 【东方到家家务节】 年中底价！更有疯狂砍价0元购、整点抢免单！ 【提供项目】 *保洁服务——日式居家保洁、保洁包年 *深度清洁——厨房保洁、空调清洗、浴室蒸洗、洗衣机清洗、电器清洗、床品除螨、地面打蜡、玻璃清洗 *保姆服务——做饭阿姨、星级保姆、月嫂 *家务服务——包年家务服务 *会员商店——海内外优质家庭用品 【服务亮点】 （1）非中介平台，100%东方自有员工 （3）员工培训上岗，技能认证、身份认证、健康认证三重把关 （4）4小时标准服务时长，6区40项108条标准 （5）服务全程上保险，全面保障安全。
                        全国客服热线：085128223691
                        微信公众号：东方到家公众号。
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
export default {
	name: 'homePage',
	components: {
	},
  methods:{
    dld(){
      location.href = 'https://www.pgyer.com/tyTM'
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
  .home{
    background:#fff;
  }
  .homeComAbout{
    padding-top:50px;
    .homeTitle{
      font-weight: 700;
      color: #303133;
      font-size: 2rem;
    }
    .titleIntroduce{
      color: #606266;
      font-size:1.1rem ;
      padding-bottom:14px;
    }
    .center{
      text-align: center;
    }
    .banner-img{
      width: 100%;
      height: 640px;
      >img{
        width: 1140px;
        height: 100%;
      }
    }
  }
</style>
